.ranking-widget {
  margin-top: 20px;
  font-family: Roboto, sans-serif;
  .ranking-item {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .ranking-number {
      border-radius: 50%;
      padding: 2px 9px 1px 8px;
      margin: 0 14px 0 5px;
      font-size: 23px;
      font-weight: 900;
      color: #ffffff;
    }
    .categories {
      & > span {
        display: block;
      }
      .category-l2 {
        font-size: 14px;
        color: #485059;
        font-weight: 500;
      }
      .category-l1 {
        font-size: 11px;
        color: #485059;
        font-weight: 300;
      }
    }
  }
}
