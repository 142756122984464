@import '../../core-ui/styles/scrollBar';

.pck-theme-picker {
  position: absolute;
  width: 250px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: white;
  margin-top: 4px;
  z-index: 10;

  .theme-section {
    @extend %scrollbar;
    padding: 20px 6px;
    overflow-y: scroll;
    min-height: 200px;
    height: calc(100vh - 980px);
    .themeForm {
      .form-check {
        margin: 3px;
        label {
          display: flex;
          justify-content: space-between;
          border: 1px solid transparent;
          padding: 8px;
          cursor: pointer;
          .color-container {
            display: inline-flex;
            width: 32px;
            height: 32px;
          }
        }
        input[type='radio'] {
          display: none;
          width: 0;
          margin-left: 0;
        }
        input[type='radio']:checked + label,
        input[type='radio']:hover + label {
          border: 1px solid #cccccc;
        }
      }
    }
  }
}
