.sortAction {
  font-size: 12px;
  color: #9a9a9a;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-left: auto;
  margin-right: 0;
  span {
    text-decoration: underline;
    color: #1b1b1b;
    cursor: pointer;
  }
}
