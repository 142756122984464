:root {
  --tooltip: #333e49;
}
.pck-tooltip {
  position: relative;
  display: grid;
  &:hover .tooltiptext {
    visibility: visible;
  }
  .tooltiptext {
    visibility: hidden;
    background-color: var(--tooltip);
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    padding: 10px 15px;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
    min-width: 150px;
    pointer-events: none;
    font-family: Roboto, sans-serif;
    &::after {
      content: '';
      position: absolute;
      border-style: solid;
    }
    &.top {
      bottom: 100%;
      left: 50%;
      margin-left: -60px;
      &::after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-color: var(--tooltip) transparent transparent transparent;
      }
    }
    &.bottom {
      top: 100%;
      left: 50%;
      margin-left: -60px;
      &::after {
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-color: transparent transparent var(--tooltip) transparent;
      }
    }
    &.left {
      top: -5px;
      right: 105%;
      &::after {
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-color: transparent transparent transparent var(--tooltip);
      }
    }
    &.right {
      top: -5px;
      left: 105%;
      &::after {
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-color: transparent var(--tooltip) transparent transparent;
      }
    }
  }
}
