.toolbox-card {
  background: #3a4147;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: grab;
}

.toolbox-card-image {
  justify-content: center;
  display: flex;
  margin-bottom: 8px;
  & img {
    width: 100%;
  }
}

.toolbox-card-content {
  padding: 3px;
}

.title {
  width: 235px;
  color: #c0c6cc;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 11px;
  margin-bottom: 8px;
}

.description-text {
  width: 235px;
  color: #c0c6cc;
  font-family: Roboto;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 11px;
  margin-bottom: 11px;
}
