@import '../../core-ui/styles/scrollBar';

.pck-color-picker {
  position: absolute;
  width: 250px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: white;
  margin-top: 4px;
  z-index: 10;

  .color-section {
    @extend %scrollbar;
    padding: 20px 20px;
    overflow-y: scroll;
    min-height: 200px;
    height: calc(100vh - 980px);
    .colorForm {
      display: flex;
      flex-wrap: wrap;
      margin: -16px;
      align-content: center;
      .form-check {
        flex: 1 1 12px;
        margin: 3px;
        label {
          display: block;
          width: 32px;
          height: 32px;
          cursor: pointer;
          border: 1px solid transparent;
          padding: 8px;
          box-sizing: content-box;
          .color-container {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        input[type='radio'] {
          display: none;
          width: 0;
          margin-left: 0;
        }
        input[type='radio']:checked + label,
        input[type='radio']:hover + label {
          border: 1px solid #cccccc;
        }
      }
    }
  }
}
