.diy-date-range-container {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  border-radius: 5px;
  position: relative;
}

.diy-icon-container {
  display: flex;
  align-items: end;
  padding-left: 1px;
}

.diy-filtering-vertical-line {
  position: absolute;
  height: 100%;
  border-left: 1px solid gray;
  top: 0;
}

.diy-filtering-icon {
  color: '#BEC2CB';
  margin: 5px;
}

.diy-filtering-icon:hover {
  cursor: pointer;
}

.diy-calendar-input {
  font-size: 12px;
  outline: none;
  padding: 5px 0 0 5px;
  color: #4a535b;
  transition: 0.1s ease-out;
  border: 1px solid gray;
}
