.switchAction {
  display: inline-flex;
  margin-left: auto;
  margin-right: 0;
  span {
    padding: 4px 14px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #edecec;
      border: 1px solid #edecec;
    }
    &.active {
      background-color: #edecec;
      border: 1px solid #c5c5c5;
      &:hover {
        border: 1px solid #c5c5c5;
      }
    }
    .icon {
      margin-right: 5px;
      width: 1em;
    }
  }
}
