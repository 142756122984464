.caret-icon {
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 6px solid #00a0af;
  margin-left: none;
  margin-right: 5px;
}

.arrow {
  width: 50px;
  position: absolute;
  height: 10px;
  overflow: hidden;
  z-index: 1000;

  :before {
    content: '';
    width: 50px;
    position: absolute;
    height: 50px;
    transform: rotate(45deg);
    top: 12px;
    border: 1px solid red;
    border: 1px solid #bfbfbf;
    z-index: 999;
    background: white;
  }
}

.arrow-svg {
  width: 15px;
  height: 15px;
  margin-right: 0 !important;

  path {
    fill: hsl(0, 0%, 80%);
  }
}
