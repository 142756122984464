.chat-transcript {
  border: 1px solid #cccccc;
  padding: 10px 15px;
  .chat-transcript-header {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    color: #575755;
    font-size: 16px;
    border-bottom: 1px solid #cccccc;
    .transcript-info {
      width: 40%;
      display: flex;
      gap: 30px;
      font-size: 16px;
      span {
        &:first-child {
          font-weight: bold;
          color: #33929e;
        }
      }
    }
    .close {
      cursor: pointer;
    }
  }
  .chat-transcript-body {
    color: #575755;
    font-size: 14px;
    .chat-transcript-summary {
      padding: 15px 0;
      font-size: 16px;
      border-bottom: 1px solid #cccccc;
      span {
        font-weight: bold;
      }
      p {
        margin: 8px 0 0 0;
      }
    }
    .scroll {
      max-height: 400px;
      overflow-y: scroll;
      padding: 0 8px 0 0;
      .chat-transcript-system-message {
        padding: 10px 0;
        font-size: 14px;
        color: #9c9c9c;
        .time {
          font-size: 13px;
          color: #9c9c9c;
        }
        p {
          margin: 0;
        }
      }
      .chat-transcript-message {
        padding: 10px 0;
        .details {
          display: flex;
          gap: 10px;
          flex-direction: row-reverse;
          .thumbnail {
            padding: 8px 13px 3px 13px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            font-size: 16px;
            background-color: #ccecef;
            color: #575755;
            box-sizing: border-box;
          }
          .message {
            width: 80%;
            display: flex;
            gap: 10px;
            flex-direction: row-reverse;
            p {
              background-color: #ccecef;
              padding: 10px;
              border-radius: 5px 0 5px 5px;
              margin: 0;
              color: #575755;
              width: 100%;
              font-size: 14px;
            }
          }
        }
        .time {
          font-size: 13px;
          color: #9c9c9c;
          display: flex;
          justify-content: flex-end;
          margin-top: 8px;
        }
        &.customer {
          .details {
            flex-direction: row;
            .thumbnail {
              background-color: #edefef;
              color: #575755;
            }
            .message {
              flex-direction: row;
              p {
                background-color: #edefef;
                border-radius: 0 5px 5px 5px;
              }
            }
          }
          .time {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
