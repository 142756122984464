.pck-accordion-button {
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pck-accordion-title {
  flex: 1;
}

.pck-accordion-icon {
  font-size: 1.5rem;
  color: #909090;
}

.pck-accordion-icon {
  font-size: 1.5rem;
  transition: transform 0.2s;
}

.pck-accordion-icon.down {
  transform: rotate(180deg);
}

.pck-accordion-icon.up {
  transform: rotate(0deg);
}

.triangle-icon::before {
  content: '\25B2';
  color: #909090; /* Set your desired color */
  font-size: 25px; /* Set your desired font size */
}
