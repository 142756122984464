.diy-down-icon {
  transform: rotate(180deg);
  height: 18px;
}
.diy-up-icon {
  height: 18px;
}
.diy-ctrl-container {
  width: 25px;
  line-height: 0;
}
.diy-input-number::-webkit-inner-spin-button,
.diy-input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.diy-input-number {
  -moz-appearance: textfield;
  border: 0px solid gray !important;
}
