.highlightValue {
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-top: 20px;
  color: #38474f;
}

.horizontal-sentiment-stacked-bar {
  width: 100% !important;
  height: 1.6rem;
  & > * {
    height: 100% !important;
  }
}
