.chatSegments {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 12px;
}

.segment-container {
  margin: 0 auto;
}

.segment-title {
  font-size: 1.8em;
  color: rgba(0, 0, 0, 0.87);
}

.gauge-value {
  font-size: 1.6em;
  padding-bottom: 3px;
  font-weight: bold;
}

.count-messages {
  font-size: 2.1em;
  color: #00a0af !important;
}

.total-duration {
  font-size: 1em;
  color: #00a0af !important;
}

.segment-box {
  &:after {
    content: '';
    width: 1px;
    min-width: 1px;
    background-color: #d4d4d4;
    margin-top: 5em;
  }

  &:last-child:after {
    display: none;
  }
}

.roles-box {
  margin: 0 auto;

  .icon-roles {
    font-size: 1em;
    color: #9a9a9a;
  }

  .roles {
    font-weight: bold;
    max-width: 75px;
    margin: 0 auto;
    border-bottom: 1px solid #00a0af;
  }

  .roles-values {
    div {
      color: #00a0af;
      font-weight: 700;
    }
    &.left {
      border-right: 1px solid #00a0af;
    }
  }
}

.steps {
  position: relative;
}

.icon-steps {
  border-left: 15px solid #ffffff;
  border-right: 15px solid #ffffff;
  background-color: #ffffff;
  z-index: 100;
  position: relative;
  color: #9a9a9a;
  font-size: 1.6em;
  width: 20px;
}

.line-steps {
  height: 1px;
  background-color: #9a9a9a;
  position: absolute;
  top: 9px;
  width: 100%;
  z-index: 0;
}

.mx-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
}

.mt-5 {
  margin-top: 3em !important;
}

.flex-it,
.d-flex {
  display: flex;
}

.flex-fill {
  flex-basis: auto;
  flex: 1;
}

.w-150 {
  width: 150px !important;
  max-width: 150px !important;
}

.text-center {
  text-align: center;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5em !important;
}

.flex-content-center,
.justify-content-center {
  justify-content: center;
}
.flex-content-around,
.justify-content-around {
  justify-content: space-around;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5em !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.p-3 {
  padding: 1em !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.5em !important;
}

.pl-3,
.px-3 {
  padding-left: 1em !important;
}

.mr-3,
.mx-3 {
  margin-right: 1em !important;
}

.ml-3,
.mx-3 {
  margin-left: 1em !important;
}
