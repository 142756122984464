.diy-drawer {
  position: fixed;
  background-color: #ffffff;
  font-size: 14px;
  height: 96%;
  z-index: 9950;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  right: -281px;
  display: flex;
  align-items: center;
  border-radius: 5px 0 0 5px;
  border-top: 1px solid #4a535b;
  border-bottom: 1px solid #4a535b;
  border-left: 1px solid #4a535b;
  margin: 10px 0;
  top: 0;
  &.open {
    right: 0px;
  }

  .diy-drawer-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;

    .diy-drawer-title {
      color: #4a535b;
      font-weight: bold;
      font-size: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .diy-drawer-handle {
      position: absolute;
      top: 30%;
      left: -30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 120px;
      font-size: 16px;
      background: #ffffff;
      border-radius: 5px 0 0 5px;
      border-top: 1px solid #4a535b;
      border-bottom: 1px solid #4a535b;
      border-left: 1px solid #4a535b;
      cursor: pointer;
      pointer-events: auto;

      .diy-drawer-handle-icon {
        transform: rotate(270deg);
        height: 20px;
        width: 45px;
        color: #4a535b;
        font-weight: bold;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
      }
    }
  }
  .diy-drawer-section {
    padding: 0px 15px 15px;
    margin: 0 5px;
    border-top: 1px solid #cccccc;
    .diy-drawer-section-label {
      font-size: 16px;
      margin: 10px 0 12px 0;
      color: #909090;
      display: block;
    }
  }

  .diy-drawer-advanced-filters-section {
    padding: 10px 0px 15px;
    margin: 0 5px;
    border-top: 1px solid #cccccc;
    .diy-drawer-section-label {
      font-size: 16px;
      margin: 10px 0 12px 0;
      color: #909090;
      display: block;
    }
  }

  .diy-drawer-hierarchy-filters-section {
    padding: 10px 0px 15px;
    margin: 15px 0px;
    border-top: 1px solid #cccccc;
    .diy-drawer-section-label {
      font-size: 16px;
      margin: 10px 0 12px 0;
      color: #909090;
      display: block;
    }
  }

  .diy-drawer-contents {
    height: calc(100% - 130px) !important;
    min-width: 290px;
    flex: 1;
    overflow-y: auto;
  }

  .diy-drawer-controls {
    padding: 20px 10px;
    border-top: 1px solid #cccccc;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
  }

  .diy-filters-label {
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto;
    color: #666666;
    margin-bottom: 4px;
    display: block;
  }

  .cx-select-ui__value-container {
    width: 65px;
  }
}
