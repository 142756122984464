.summarySection {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  display: flex;

  .QLABaseSize {
    height: 25px;
    padding: 0.3em 0.3em 0.3em 0;
  }

  .base-size-span {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #666 !important;
  }

  .base-size-bold {
    font-weight: 600 !important;
    color: #333e48 !important;
  }
}
.questionText {
  margin: 20px 0 10px 0;
}
.summaryStats {
  display: flex;
  flex-direction: row;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  padding-bottom: 1em;
  .largeValueTextBlue {
    color: #0071c1;
    font-size: 35px;
    font-weight: bold;
    margin-top: -6px;
    margin-bottom: 3px;
  }
  .smallTextFormatTiles {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #666 !important;
    padding-bottom: 0.2em;
  }
  .cardboxBorder {
    border-left: 1px solid #bdbdbd;
    padding: 0.3em 1em;
    &:first-child {
      border-left: none;
      padding: 0.3em 1em 0.3em 0.5em;
    }
  }
}
