.biggest-movers {
  margin-top: 25px;
  font-family: Roboto, sans-serif;

  .top-cat {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cat-group {
      font-size: 13px;
      color: #485059;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .cat-icon {
      .printSVG {
        display: block;
        margin: 20px auto 0 auto;
      }
    }
  }

  .biggest-labels {
    font-size: 11px;
    color: #485059;
    margin-left: 0px;
    margin-top: 5px;
  }

  .biggest-l1label {
    font-weight: 300;
    letter-spacing: 0;
    line-height: 11px;
  }

  .biggest-l2label {
    font-size: 14px;
    font-weight: 500;
  }

  .biggest-greenText {
    text-align: center;
    margin-top: 7px;
    color: #4caf50;
    position: relative;
    top: -5px;
    font-weight: 600;
    font-size: 16px;
  }

  .biggest-redText {
    text-align: center;
    margin-top: 7px;
    color: #d4002d;
    position: relative;
    top: -5px;
    font-weight: 600;
    font-size: 16px;
  }

  .biggest-yellowText {
    text-align: center;
    margin-top: 7px;
    color: #ff9800;
    position: relative;
    top: -5px;
    font-weight: 600;
    font-size: 16px;
  }

  .biggest-category-group {
    font-size: 13px;
    color: #485059;
    font-weight: bold;
  }

  .biggest-green-arrow {
    color: #4caf50;
    fill: #4caf50;
    position: center;
    top: -5px;
    font-size: 20px;
    vertical-align: text-bottom;
  }

  .biggest-red-arrow {
    color: #f44336;
    fill: #f44336;
    position: center;
    top: -5px;
    font-size: 20px;
    vertical-align: text-bottom;
  }

  .biggest-yellow-arrow {
    color: #ff9800;
    fill: #ff9800;
    position: center;
    top: -5px;
    font-size: 20px;
  }

  .biggest-dashbd-exec-delta-text {
    font-size: 13px;
    color: #485059;
    font-weight: 500;
    margin-top: 5px;
  }

  .cat-text {
    font-size: 13px;
    color: #485059;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}
