%scrollbar {
  &::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    transition: all 0.5 linear;
    background: #999999;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }

  &::-webkit-scrollbar-thumb:active {
    background: #999999;
  }

  &::-webkit-scrollbar-track {
    transition: all 0.5 linear;
    background: #eeeeee6b;
    border: 0px none transparent;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-track:hover {
    background: #eeeeee;
  }

  &::-webkit-scrollbar-track:active {
    background: #eeeeee;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
