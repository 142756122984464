.meatball-menu {
  margin-left: 24px;
  height: 14px;
}
.meatball-icon {
  cursor: pointer;
  font-size: 15px;
}

.menu-options {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.menu-options ul {
  list-style-type: none;
  padding: 0;
}

.menu-options ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.menu-options ul li:hover {
  background-color: #f0f0f0;
}
