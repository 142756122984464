.session-explorer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  gap: 10px;

  .grid {
    flex: 1;
    transition: width 0.3s ease;
    overflow: auto;
    min-width: 50%;
  }

  .transcript {
    width: 0;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width 0.3s ease;
    max-height: 35%;
    &.open {
      width: 35%;
    }
  }
}
