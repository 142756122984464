.tabs-content {
  .tab-item {
    .performer-content {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      .pck-performance-table {
        height: fit-content;
        width: 65%;
      }
      .responsive-chart {
        width: 34%;
        > * {
          height: calc(100% - 60px) !important;
        }
      }
    }
  }
}
