@import '../../core-ui/styles/scrollBar';
.base-size-span {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #666;
}
.base-size-bold {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #333e48;
}

.pck-table-link {
  font-weight: bold;
  font-size: 16px;
  color: #33929e;
  cursor: pointer;
}

.pck-table-link span {
  color: #33929e !important;
}

.pck-data-grid-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.green-face {
  background-color: #99d15a;
  width: 25px;
  height: 25px;
  align-self: center;
  padding: 3px 3px 3px 3px;
  border-radius: 5px; /* Apply border radius */
}

.red-face {
  background-color: #ba050a;
  width: 25px;
  height: 25px;
  align-self: center;
  padding: 3px 3px 3px 3px;
  border-radius: 5px; /* Apply border radius */
}

.pck-first-colum-width {
  width: 70%;
}

.yellow-face {
  background-color: #fcbe23;
  width: 25px;
  height: 25px;
  align-self: center;
  padding: 3px 3px 3px 3px;
  border-radius: 5px; /* Apply border radius */
}
.biggest-green-arrow {
  color: #99d15a;
  fill: #99d15a;
  position: center;
  top: -5px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.biggest-red-arrow {
  color: #ba050a;
  fill: #ba050a;
  position: center;
  top: -5px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.biggest-yellow-arrow {
  color: #fcbe23;
  fill: #fcbe23;
  position: center;
  top: -5px;
  font-size: 20px;
}

.dt-max-height {
  height: 100%;
  padding-top: 10px;
}

.dt-max-height-fnote {
  height: calc(100% - 110px);
  padding-top: 10px;
}
.dt-min-height {
  height: calc(100% - 220px);
}

.dt-min-height-chart {
  height: calc(100% - 190px);
}
.separator-line {
  border-top: 1px solid #c4c4c4;
}
.face-container {
  border-radius: 5px; /* Apply border radius */
  display: flex;
  height: 30px;
  width: 30px;
}
.table-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: calc(100% - 60px);
  overflow: hidden;
  margin-top: 2px;
  padding-top: 4px;

  .data-grid {
    &.sc-container {
      @extend %scrollbar;
      overflow-y: scroll;

      & > div:nth-child(1) > div:nth-child(1) {
        overflow: unset;
      }
    }

    &.fixed-header {
      .rdt_TableHead {
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }
  }
}

.pck-tooltip-title {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  margin: auto;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: bold;
  text-transform: capitalize;
}

.pck-parent {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.pck-child-div,
.pck-child-span {
  margin: 0 10px;
}

.pck-value-width {
  width: 50px;
}

.circle {
  height: 25px;
  width: 25px;
}
