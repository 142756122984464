.pck-performance-table {
  display: table;
  border-collapse: collapse;
  font-family: 'Roboto', sans-serif;
  table-layout: fixed;
  width: 100%;
  .table-row {
    border-bottom: 1px solid #dddddd;
    color: #333333;
    cursor: pointer;
    &.head {
      display: table-header-group;
      .header-item {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.5em;
        text-align: center;
        vertical-align: top;
        display: table-cell;
        padding: 4px 0;
        &:last-child {
          text-align: left;
        }
      }
    }
    &.body {
      font-size: 12px;
      font-weight: 500;
      display: table-row;
      &.selected {
        .body-item {
          background-color: #e5f4f7;
          &:first-child {
            span {
              background-color: #166cbe;
              color: #fff;
            }
          }
        }
      }
    }
    .body-item {
      vertical-align: middle;
      padding: 0.6em 0.8em;
      text-align: center;
      .truncate-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      display: table-cell;
      &:nth-child(2) {
        text-align: justify;
      }
      &:first-child {
        span {
          background-color: #e5f4f7;
          padding: 0.2em 0.6em;
          border-radius: 4px;
        }
      }
    }
  }
}
