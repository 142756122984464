.pck-expand {
  background-color: transparent;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  .pck-expand-button {
    cursor: pointer;
    background-color: #33929e;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    .pck-expand-icon {
      rotate: 180deg;
      width: 24px;
      height: 28px;
      color: #ffffff;
      &.pck-expanded {
        rotate: 0deg;
      }
    }
    &:hover {
      background-color: #2b7d87;
    }
  }
}
