.sort-icon {
  max-width: 11px;
  max-height: 11px;
  margin-top: -4px;
  margin-right: 3px;

  svg {
    max-width: 11px;
    max-height: 11px;
  }
}

.asc .sort-icon,
.desc .sort-icon {
  max-width: 8px;
  max-height: 8px;
  margin-top: -7px;
  margin-right: 3px;

  svg {
    max-width: 8px;
    max-height: 8px;
  }
}
