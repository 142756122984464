.highlightValue {
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-top: 20px;
  color: #38474f;
}
.responsive-chart.horizontal-stacked-bar {
  height: calc(100% - 85px) !important;
}
