.individual-metric {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .metric-value {
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #3a4647;
    padding-bottom: 20px;
  }
  .metric-gauge {
    align-items: center;
    margin-top: -18px;
  }
}
