.tooltip-title {
  font-family: Roboto, sans-serif;
  margin: auto;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: bold;
}

.tooltip-text {
  margin: auto;
  padding: 5px;
  color: rgb(160, 157, 157);
  width: fit-content;
}

.tooltip-container {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  background-color: rgba(34, 44, 51, 1) !important;
}

.placeholder-container {
  display: flex;
  flex-direction: row;
}

.placeholder-text {
  position: absolute;
  top: 50%;
  right: 66%;
  transform: translateY(-50%);
}
