.bullet-chart-widget {
  margin: 20px 5px 0 5px;

  .bullet-chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;

    .bullet-chart-label {
      color: #37474f;
      font-size: 12px;
    }

    .bullet-chart-value {
      font-size: 14px;
    }
  }

  .bullet-chart-xaxis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    font-size: 8px;
    color: #7d7d7d;
  }
}

.drawer-scroll {
  height: calc(100% - 30px) !important;
}

.fix-height-scroll {
  height: 300px !important;
}
