.no-data {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #8a8a8a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 25px);
  width: 100%;
  padding: 0;
  margin: 0;
}
