.multiple-summary-metrics {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;

  .metric-child {
    position: relative;
    align-content: center;
    width: 100%;

    &:nth-child(n + 2) {
      width: 50%;
      border-top: 1px solid #b7b7b7;
      align-items: center;

      .metric-value {
        font-size: 30px;
      }

      .metric-breakdown {
        font-size: 12px;
        font-weight: 300;
        color: #485859;
        padding-left: 8px;
      }
    }

    .metric-header {
      // follows some .cardHeader properties
      margin-bottom: 5px;
      margin-left: 3px;
    }

    .metric-title {
      font-size: 14px;
      font-weight: 500;
      color: #0e1212;
    }

    .metric-value {
      font-size: 36px;
      font-weight: 700;
      color: #3a4647;
    }

    .metric-unit {
      font-size: 12px;
      font-weight: 300;
      color: #485859;
      padding-left: 8px;
    }
  }
}
