.text-analytics-widget {
  padding: 15px;
  .main-widgets {
    display: flex;
    & > * {
      flex: 1;
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .dependent-widgets {
    & > * {
      margin-top: 10px;
    }
  }
}
