.drawer {
  position: fixed;
  background-color: #242c33;
  padding: 10px;
  font-size: 14px;
  height: 96%;
  z-index: 9800;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  right: -281px;
  top: 0;
  border-radius: 5px 0 0 5px;
  margin: 10px 0;

  &.open {
    right: 0px;
  }

  .drawer-container {
    height: 100%;
    position: relative;
    font-family: 'Roboto', sans-serif;

    .drawer-title {
      color: #999999;
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 10px;
    }

    .drawer-handle {
      position: absolute;
      top: 30%;
      left: -40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 120px;
      font-size: 16px;
      background: #242c33;
      border-radius: 5px 0 0 5px;
      cursor: pointer;
      pointer-events: auto;

      .drawer-handle-icon {
        transform: rotate(270deg);
        height: 20px;
        width: 45px;
        color: #c0c6cc;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
      }
    }

    .drawer-close {
      @extend .drawer-handle;
      top: 45%;
      height: 40px;
      padding-left: 2px;
    }
  }

  .dashboard-view-title {
    color: #999999;
    margin-bottom: 4px;
  }

  .dashboard-view-options {
    margin-bottom: 14px;
    margin-right: 4px;
    .dashboard-view-label {
      color: #999999;
      font-size: 12px;
      input {
        margin-right: 8px;
      }
    }
  }

  .drawer-search {
    background: #3a4147;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 10px;

    .input-box {
      display: flex;
      flex-direction: row;
      padding-right: 1px;
      background: #3a4147;
      justify-content: space-between;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      border-radius: 2px;
    }

    .search-input {
      border: none;
      outline: none;
      padding: 0px;
      font-size: 14px;
      font-weight: normal;
      color: #eeeeee;
      background-color: #3a4147;
      border-radius: 4px;
      box-sizing: border-box;
      padding-right: 12px;
      width: 90%;
      font-family: Roboto;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 11px;
    }

    .x-icon-search {
      opacity: 0.6;
      box-sizing: border-box;
      cursor: pointer;
      min-height: 21px;
      position: relative;
      left: -3px;
      top: 2px;
    }

    .search-icon {
      cursor: pointer;
    }
  }
  .drawer-scroll {
    height: calc(100% - 85px) !important;
    min-width: 270px;
    & > div {
      overflow-x: auto !important;
    }
    &.with-diy {
      height: calc(100% - 275px) !important;
    }
  }
}
