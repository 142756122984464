.pck-card-title-container {
  display: inline-flex;
  width: 100%;
  &.pck-actions {
    width: auto;
    max-width: calc(100% - 10em);
    min-width: 145px;
  }
  .pck-card-title {
    color: #38474f;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
    cursor: default;
    &.metric-headers {
      color: #0e1212;
      font-size: 14px;
      font-weight: 500;
    }
  }
  & > svg {
    margin-left: 5px;
    width: 14px;
  }
  .tooltip-container {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    background-color: rgba(34, 44, 51, 1) !important;
  }
}
