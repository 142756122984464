.diy-button {
  margin: 0 auto;
  width: 43%;
  padding: 10px;
  top: 50%;
  left: 50%;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;

  &.diy-button-enabled {
    border: 1px;
    background-color: #00a0af;
    color: #ffffff;
    font-weight: 300;

    &:disabled {
      pointer-events: none;
      background-color: lightgray;
    }
  }

  &.diy-button-enabled-inverse {
    border: 1px solid #cccccc;
    background-color: #fcfdfd;
    color: #00a0af;
    font-weight: 300;
  }

  &.diy-button-disabled {
    border: 1px solid #cccccc;
    background-color: #fcfdfd;
    color: #cccccc;
    font-weight: 300;
  }
}

.diy-input-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 0px;
  position: relative;
  &:has(.diy-material-number-field) {
    margin: 0;
  }
  .diy-input {
    font-size: 12px;
    outline: none;
    padding: 11px 10px;
    color: #4a535b;
    transition: 0.1s ease-out;
    border: 1px solid gray;

    &[type='text']:focus {
      border-color: #cccccc;
    }

    &[type='text']:focus + .diy-color-input {
      border-top: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
    }

    &[type='text']:focus + label {
      color: #cccccc;
      top: 0;
      transform: translateY(-50%) scale(0.9) !important;
    }

    &[type='text']:not(:placeholder-shown) + label {
      top: 0;
      transform: translateY(-50%) scale(0.9) !important;
    }

    &[type='text']:required {
      border: 1px solid red;
    }
  }

  .character-warning-container {
    display: flex;
    justify-content: flex-end;
    &:has(.character-limit-message) {
      justify-content: space-between;
    }
    .character-limit-message {
      font-size: 10px;
      color: red;
      margin-top: 5px;
    }
    .character-count {
      font-size: 10px;
      margin-top: 5px;
    }
  }

  .diy-label {
    position: absolute;
    font-size: 12px;
    left: 0;
    top: 0%;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;

    &.select {
      font-size: 10px;
    }
  }

  .diy-label-slider {
    position: absolute;
    font-size: 12px;
    left: 0;
    top: -3px;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }

  .diy-material-textfield {
    position: relative;
    display: flex;

    .diy-input {
      width: 100%;
      border-radius: 3px;
    }
  }
  .diy-select-goal-line {
    position: relative;

    .diy-input {
      width: 100%;
      border-radius: 3px;
    }
  }

  .diy-material-textarea {
    position: relative;
    display: flex;
    .diy-input {
      width: 100%;
      border-radius: 3px;
      resize: vertical;
      max-height: 165px;
    }
  }

  .diy-material-numberfield {
    position: relative;
    display: flex;
    border: 1px solid gray;
    border-radius: 0 3px 3px 0;
    border-left: 0px;
    .diy-input {
      width: 100%;
    }
  }

  .diy-material-number-field {
    position: relative;
    display: flex;
    border: 1px solid gray;
    border-radius: 0 3px 3px 0;
    .diy-input {
      width: 100%;
    }
  }

  .diy-material-colorfield {
    position: relative;
    display: flex;
    align-content: center;

    .diy-input {
      width: 80%;
      border-radius: 3px 0 0 3px;
      cursor: pointer;
    }

    .diy-color-input {
      width: 20%;
      border-radius: 0 3px 3px 0;
      border-top: 1px solid gray;
      border-right: 1px solid gray;
      border-bottom: 1px solid gray;
      position: relative;
      cursor: pointer;

      &:hover,
      &:focus {
        border-top: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
      }

      .diy-selected-color {
        top: 6px;
        bottom: 6px;
        left: 10px;
        right: 10px;
        position: absolute;
      }

      .diy-theme-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0;
        padding: 10px;

        .diy-selected-theme {
          aspect-ratio: 1;
        }
      }
    }
  }

  .diy-material-trend {
    position: relative;
    display: flex;
    align-content: center;

    .diy-select {
      width: 80%;
    }

    .dyi-number-input {
      width: 20%;
    }

    .diy-input-container {
      margin: 0px;
    }
  }

  .diy-select {
    width: 100%;
    max-width: 250px;

    & > div {
      border: 1px solid gray;
    }

    .diy-option {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 10px;
        min-width: 20px;
      }

      .flex-fill {
        flex: 1;
      }

      .hidden {
        opacity: 0;
      }
    }

    .diy-select-label {
      font-size: 12px;
    }

    div[class*='ValueContainer'] {
      max-width: 210px;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    div[class*='indicatorContainer'] {
      svg {
        max-width: 14px;
      }
    }
  }
}

.diy-required-field {
  color: red;
  padding: 5px;
  font-size: 9px;
}

.option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  max-width: 250px;

  .toggler-label {
    color: #4a535b;
  }

  .toggler-wrapper {
    display: block;
    width: 45px;
    height: 25px;
    cursor: pointer;
    position: relative;
  }

  .toggler-wrapper input[type='checkbox'] {
    display: none;
  }

  .toggler-wrapper input[type='checkbox']:checked + .toggler-slider {
    background-color: #a4c9d1;
  }

  .toggler-wrapper .toggler-slider {
    background-color: #9e9e9e;
    position: absolute;
    border-radius: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .toggler-wrapper .toggler-knob {
    position: absolute;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .toggler-wrapper input[type='checkbox']:checked + .toggler-slider .toggler-knob {
    left: calc(100% - 19px - 3px);
  }

  .toggler-wrapper .toggler-knob {
    width: calc(25px - 6px);
    height: calc(25px - 6px);
    border-radius: 50%;
    left: 3px;
    top: 3px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
  }
}

.is-child {
  margin-left: 30px;
  .diy-select-description {
    font-size: 12px;
    line-height: 1.3;
    margin-top: 5px;
  }
}

.diy-select {
  div[id*='-listbox'] div[class*='option'] {
    transition: all 0.2s ease-out;
    &:has([class*='pck-closed']) {
      display: none;
    }
  }
}
