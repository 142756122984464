.tooltip-title {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  margin: auto;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: bold;
  text-transform: capitalize;
}

.tooltip-text {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  margin: auto;
  padding: 5px;
  color: rgb(160, 157, 157);
  width: fit-content;
}

.tooltip-container {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  background-color: rgba(34, 44, 51, 1) !important;
}

.placeholder-container {
  display: flex;
  flex-direction: row;
}

.placeholder-text {
  position: absolute;
  top: 50%;
  right: 66%;
  transform: translateY(-50%);
}

.helper-text {
  font-family: Roboto, sans-serif;
  padding: 8px 12px;
  background-color: #999999;
  color: #ffffff;
  cursor: auto;
  font-size: 12px;
}

.collapsed {
  display: none !important;
}

.group-heading {
  flex: '1 1';
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.groups-arrow-up {
  transform: rotate(270deg);
}
.icon-container {
  position: static;
  width: 5%;
  align-items: center;
}

.label-container {
  position: static;
  width: 94%;
  align-items: center;
  text-transform: capitalize;
}

.option-groups {
  text-indent: 20px;
}

.custom-option {
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;

  &:hover {
    background-color: #d2e3f9;
  }
}
