.pck-modal {
  position: fixed;
  z-index: 9900;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  font-family: 'Roboto', sans-serif;
  overflow-y: hidden;
  .open {
    opacity: 1;
  }
}

.modal-box {
  max-width: 80%;
  margin: 0 auto;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 24px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 2px;
  .modal-header {
    position: relative;
    font-size: 16px;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;

      path: {
        fill: white;
      }
    }
  }
}
