.metric-collection {
  display: flex;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;

  .metric-box {
    padding: 10px 15px;
    min-width: calc(25% - 10px);
    box-sizing: border-box;
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
      min-width: calc(25% - 5px);
    }

    &:last-child {
      margin-right: 0;
      min-width: calc(25% - 5px);
    }
  }

  .dashbd-metric-numb {
    color: #fff;
    font-size: 38px;
    font-weight: 600;
  }

  .dashbd-metric-title {
    color: #d8d8d8;
    margin-top: 10px;
    font-size: 14px;
  }

  .dashbd-metric-delta-txt {
    color: #bfbfbf;
    position: relative;
    top: -5px;
    font-size: 12px;
    padding-left: 3px;
  }

  .delta-txt {
    color: #bfbfbf;
    top: -5px;
    font-size: 12px;
    padding-left: 3px;
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .dashbd-delta-numb {
    font-weight: 600;
  }
}
