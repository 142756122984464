.custom-widget {
  background: #3a4147;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: grab;

  .rectangle-box {
    box-sizing: border-box;
    border: 1px solid #c0c6cc;
    border-radius: 2px;
    display: flex;
    padding: 2px 0;
  }

  .icon-build-custom-widget {
    height: 40px;
    width: 40px;
    color: #c0c6cc;
    margin: 6px 4px;
  }

  .title {
    width: 185px;
    color: #c0c6cc;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 550;
    letter-spacing: 0.2px;
    line-height: 11px;
    position: relative;
    margin-top: 8px;
  }

  .description-text {
    width: 177px;
    color: #c0c6cc;
    font-family: Roboto;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 11px;
    margin-bottom: 0;
  }
}
