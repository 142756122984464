.diy-query-editor {
  .diy-drawer-datasource {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    .diy-drawer-dd {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  .diy-query-editor {
    z-index: 0;
  }
}

.diy-drawer-icon {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-end;
  align-items: end;
}
