@import '../../core-ui/styles/scrollBar';

.corporatePulse {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  padding: 0px 5px;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  max-height: calc(100% - 20px);

  &.sc-container {
    @extend %scrollbar;
    overflow-y: scroll;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  /*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

  .chart-title {
    font-size: 16px;
    font-weight: bold;
    fill: #37474f;
  }
  .line-padding {
    padding-top: 10px;
    padding-bottom: 3px;
  }
  .heaader-padding {
    padding-top: 15px;
    padding-bottom: 3px;
  }
  span {
    color: #37474f;
  }
  .row {
    margin-left: auto;
    margin-right: auto;
  }
  :-ms-input-placeholder {
    color: #bdbdbd;
  }
  .cp-row {
    border-bottom: 1px solid #ddd;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    width: 100%;
    align-items: center;

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }

  .cp-row-header {
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 6px;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .cp-row-header > div:nth-child(1),
  .cp-row > div:nth-child(1) {
    flex: 1;
  }
  .cp-row-header > div:not(:first-child),
  .cp-row > div:not(:first-child) {
    min-width: 70px;
    max-width: 70px;
    padding: 5px;
    text-align: center;
  }
  .cp-row-header > div:nth-last-child(1),
  .cp-row > div:nth-last-child(1) {
    min-width: 70px;
    max-width: 70px;
    text-align: right;
  }

  .long-label {
    cursor: pointer;

    .view-more {
      margin-left: 5px;
      color: #00a0af;
    }
  }
  .cp-subtitle {
    font-size: 14px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cp-title {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .dashbd-delta-numb-green {
    color: #4caf50;
    position: relative;
    top: -5px;
    font-weight: 600;
  }
  .dashbd-delta-numb-red {
    color: #f44336;
    position: relative;
    top: -5px;
    font-weight: 600;
  }
  .numb-blue {
    color: #216cc2 !important;
  }
  .dashbd-exec-delta-text {
    font-size: 14px;
  }
  .cp-text {
    font-size: 12px;
  }

  // Special css
  .margin-top-10px {
    margin-top: 10px;
  }

  .margin-top-minus6px {
    margin-top: -6px;
  }

  .pck-modal {
    bottom: 12px;
    left: 10px;
    right: 0;
    top: 42px;
    background-color: rgba(243, 243, 243, 0.9);
    width: 97%;
    height: 90%;
    .modal-header {
      background-color: #999;
      .title {
        color: #fff;
      }
    }
    .modal-body {
      padding: 20px;
      .text {
        line-height: 18px;
      }
    }
  }

  .diy-cp-header {
    overflow-wrap: break-word;
  }
}
