.cardHeader {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 3px;
  margin-bottom: 5px;
  position: relative;
  padding-top: 5px;
}

.card-info-actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.cardOverlay {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.6);
  justify-content: center;
  align-items: center;
  display: none;
  .cardActions {
    border-radius: 2px;
    .cardAction {
      background-color: #d8d8d8;
      padding: 15px 15px 13px 15px;
      border-color: #666666;
      border-radius: 2px;
      box-sizing: content-box;
      &:hover {
        background-color: #cccccc;
        cursor: pointer;
      }
      &:focus {
        background-color: #cccccc;
        cursor: pointer;
      }
    }
  }
}

.card {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .cardOverlay {
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
  }
  // dark widgets currently not in use
  &.dark {
    background-color: #49505a;
    padding: 10px 15px;
    .cardOverlay {
      background-color: rgba(73, 80, 90, 0.9);
    }
  }
  &.metric-card {
    padding: 10px 15px;
  }
}

.no-card {
  padding-bottom: 15px;
  .cardOverlay {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}

.card,
.no-card {
  &:hover .cardOverlay {
    display: flex;
  }
}
