@import '../../core-ui/styles/scrollBar';

.base-size-span {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #666;
}

.base-size-bold {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #333e48;
}

.dt-max-height {
  height: 100%;
  padding-top: 10px;
}

.dt-max-height-fnote {
  height: calc(100% - 110px);
  padding-top: 10px;
}

.dt-min-height {
  height: calc(100% - 220px);
}

.dt-min-height-chart {
  height: calc(100% - 190px);
}

.separator-line {
  border-top: 1px solid #c4c4c4;
}

.table-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: calc(100% - 60px);
  overflow: hidden;
  margin-top: 4px;
  padding-top: 8px;

  .dynamic-table {
    &.sc-container {
      @extend %scrollbar;
      overflow-y: scroll;

      & > div:nth-child(1) > div:nth-child(1) {
        overflow: unset;
      }
    }

    &.fixed-header {
      .rdt_TableHead {
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }
  }
}

.table-grid-foot-note {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  padding-top: 1em;
  margin-left: 6px;
  font-style: italic;
  display: block;
  font-size: 12px;
}

.question-text {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #333e48;
  margin-bottom: 8px;
}

.pck-tooltip-title {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  margin: auto;
  color: rgb(255, 255, 255);
  padding: 5px;
  font-weight: bold;
  text-transform: capitalize;
}

.l1-row {
  font-weight: initial;
}

.l2-row {
  font-weight: bold;
}

.significanceIndicator {
  margin-left: auto !important;
}

.rdt_TableRow div:first-child .l2-row {
  padding-left: 10px;
  font-weight: initial;
}

.rdt_TableRow:has(.l1-row) {
  background-color: #f1f1f1;
}

.rdt_TableRow:has(.l2-row) {
  background-color: #ffffff;
}

.rdt_TableRow div:first-child .l1-row {
  font-weight: bold;
}
