.modal-footer {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
}
.modal-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  .back {
    height: 32px;
    width: 33px;
    & > * {
      height: 32px;
      width: 33px;
      rotate: 270deg;
      padding-top: 2px;
      cursor: pointer;
    }
  }
  .label {
    font-size: 16px;
  }
}
.modal-button {
  width: 10%;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;

  &.modal-button-enabled-inverse {
    border: 1px solid #cccccc;
    background-color: #fcfdfd;
    color: #00a0af;
    font-weight: 300;
    &:hover {
      background-color: #e7e7e7;
    }
  }
}
