.group-pie-footer {
  height: 16px;
  width: 57px;
  justify-content: space-between;
  display: flex;
  color: #37474f;
  gap: 5px;
  margin: 0 3px;
  margin-top: -10px;
}

.group-icon {
  height: 11px;
  width: 17px;
}

.group-icon-text {
  height: 16px;
  width: 35px;
  color: #485159;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
