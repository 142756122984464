.diy-modal {
  &.pck-modal {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    .modal-box {
      border: 1px solid #4a535b;
      .pck-modal-body {
        min-height: 80px;
        width: 600px;
        position: relative;
      }
      .pck-modal-body-sms {
        width: 600px;
        padding: 0px 5px 0px 5px;
      }
    }
  }
}
