.top-categories {
  margin-top: 25px;
  font-family: Roboto, sans-serif;
}

.top-cat {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cat-group {
    font-size: 13px;
    color: #485059;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .cat-icon {
    .printSVG {
      display: block;
      margin: 20px auto 0 auto;
    }
  }
}

.cat-labels {
  font-size: 11px;
  color: #485059;
  margin-left: 0px;
  margin-top: 5px;
}

.cat-l1label {
  font-weight: 300;
  letter-spacing: 0;
  line-height: 11px;
}

.cat-l2label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.cat-greenText {
  text-align: center;
  margin-top: 7px;
  color: #4caf50;
  position: relative;
  top: -5px;
  font-weight: 600;
  font-size: 16px;
}

.cat-redText {
  text-align: center;
  margin-top: 7px;
  color: #d4002d;
  position: relative;
  top: -5px;
  font-weight: 600;
  font-size: 16px;
}
