.diyYValuesLabel {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #4a535b;
}

.diyYValuesInput {
  display: flex;
  align-items: center;
  gap: 10px;
}

.diyYValuesInput > input {
  border: 3px solid #737a8a;
}

.diyMinimunLabel {
  margin-right: 40px;
}

.diyMinValue {
  width: 58px;
}

.diyMaxValue {
  width: 58px;
}

.diyIncrementsLabel {
  margin-right: 45px;
}

.diyIncrementsValue {
  width: 155px;
}

.diyYAxisNumberField {
  width: 86px;
  display: flex;
  align-items: center;
}
