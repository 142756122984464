.input-box {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  padding-right: 1px;
}

.search-input {
  border: none;
  outline: none;
  padding: 0px;
  font-size: 14px;
  font-weight: normal;
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding-right: 12px;
}

.x-icon-search {
  opacity: 0.6;
  box-sizing: border-box;
  cursor: pointer;
  min-height: 21px;
  position: relative;
  left: -4px;
  top: 1px;
}

.search-icon {
  cursor: pointer;
}

.reset-label {
  color: #00a0af;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  margin-left: 12px;
}
