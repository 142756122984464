.tabs-container {
  margin-top: 20px;
  position: relative;
  .tabs-header {
    border-bottom: 1px solid #d4d4d4;
  }
  label.checked {
    color: #555;
    border: solid #d4d4d4;
    border-width: 1px 1px 0px 1px;
    border-radius: 3px 3px 0 0;
    border-bottom-color: transparent;
    background-color: #fff;
    position: relative;
    bottom: -1px;
    &:hover {
      background-color: #fff;
    }
  }
  label {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: bold;
    min-height: 42px;
    line-height: 42px;
    max-width: 300px;
    width: 32%;
    text-align: center;
    color: #00a0af;
    cursor: pointer;
    border: solid transparent;
    border-width: 1px 1px 0 1px;
    &:hover {
      background-color: #efefef;
    }
  }
  .tabs-content {
    font-size: 16px;
    padding: 0 10px;
    .tab-item {
      margin-top: 10px;
    }
  }
}
